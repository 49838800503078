<template>
  <div class="wrapper">
    <div class="card">
      <h2>账户中心</h2>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>用户中心</el-breadcrumb-item>
        <el-breadcrumb-item>账户中心</el-breadcrumb-item>
        <el-breadcrumb-item>资质认证</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="details">
      <div class="left">
        <div class="img-wrapper">
          <img src="../image/company.png" alt="company" />
        </div>
        <div class="auth-wrapper">
          <div class="inner">
            <span>已认证</span>
            <el-divider direction="vertical" />
            <span>公司</span>
          </div>
        </div>
        <p class="company-name">{{ companyDetail.companyName }}</p>
        <p class="auth-last-time">
          实名认证将在{{ companyDetail.expiryTime }}到期
        </p>
        <!-- TODO 修改信息 -->
        <p class="editBtnBox">
          <el-button type="primary" @click="handleEdit">
            实名认证信息变更
          </el-button>
        </p>
        <ul class="list">
          <li>
            <a
              href="javascript:void(0)"
              class="auth"
              :class="{ active: active === '1' }"
              @click="active = '1'"
            >
              资质认证
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              class="invoice"
              :class="{ active: active === '2' }"
              @click="active = '2'"
            >
              发票详情
            </a>
          </li>
        </ul>
      </div>
      <div class="right">
        <div class="auth-detail" v-show="active === '1'">
          <p class="title">营业执照</p>
          <ul class="list">
            <li>
              <p class="label">企业名称：</p>
              <p class="content">{{ companyDetail.companyName }}</p>
            </li>
            <li>
              <p class="label">统一社会信用代码：</p>
              <p class="content">{{ companyDetail.blicUscc }}</p>
            </li>
            <li>
              <p class="label">企业注册地：</p>
              <p class="content">
                {{ companyDetail.blicCity }}{{ companyDetail.blicProvince }}
              </p>
            </li>
            <li>
              <p class="label">住所：</p>
              <p class="content">{{ companyDetail.blicAddress }}</p>
            </li>
            <li>
              <p class="label">经营范围：</p>
              <p class="content">
                {{ companyDetail.blicScope }}
              </p>
            </li>
            <li>
              <p class="label">营业期限：</p>
              <p class="content">{{ companyDetail.blicValidityEnd }}</p>
            </li>
          </ul>
          <!----------法人证件---------->
          <p class="title">法人证件</p>
          <ul class="list">
            <li>
              <p class="label">证件类型：</p>
              <p class="content">{{ companyDetail.lepCardType }}</p>
            </li>
            <li>
              <p class="label">姓名：</p>
              <p class="content">{{ companyDetail.lepName }}</p>
            </li>
            <li>
              <p class="label">证件号码：</p>
              <p class="content">{{ companyDetail.lepCardNo }}</p>
            </li>
            <li>
              <p class="label">有效期：</p>
              <p class="content">{{ companyDetail.lepValidityEnd }}</p>
            </li>
          </ul>
          <!----------对公账户---------->
          <p class="title">对公账户</p>
          <ul class="list">
            <li>
              <p class="label">开户银行：</p>
              <p class="content">{{ companyDetail.occBankAccount }}</p>
            </li>
            <li>
              <p class="label">开户支行：</p>
              <p class="content">{{ companyDetail.occBankChildName }}</p>
            </li>
            <li>
              <p class="label">银行账号：</p>
              <p class="content">{{ companyDetail.occBankAccount }}</p>
            </li>
          </ul>
          <!----------对公账户---------->
          <p class="title">联系人信息</p>
          <ul class="list">
            <li>
              <p class="label">联系人手机号：</p>
              <p class="content">{{ companyDetail.smsPhone }}</p>
            </li>
          </ul>
        </div>

        <!--tab2 发票详情-->
        <div class="invoice-form" v-show="active === '2'">
          <p class="title">
            增值税发票信息
            <span>（与税务部门备案一致，所有信息必填）</span>
          </p>
          <el-form
            class="invoiceForm"
            ref="invoiceForm"
            :model="invoiceForm"
            :rules="invoiceFormRules"
            label-width="108px"
            size="small"
            :disabled="formDisabled"
          >
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="企业全称" prop="companyName">
                  <el-input
                    v-model="invoiceForm.companyName"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="纳税人识别号"
                  prop="taxpayerIdentificationNumber"
                >
                  <el-input
                    v-model="invoiceForm.taxpayerIdentificationNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="注册地址" prop="registerAddress">
                  <el-input v-model="invoiceForm.registerAddress"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电话号码" prop="phone">
                  <el-input v-model="invoiceForm.phone"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="开户银行" prop="bank">
                  <el-input v-model="invoiceForm.bank"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="开户银行账号" prop="bankNum">
                  <el-input v-model="invoiceForm.bankNum"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <p class="title">电子发票邮寄地址</p>
          <el-form
            class="invoiceForm"
            ref="mailForm"
            :model="mailForm"
            :rules="mailFormRules"
            label-width="108px"
            size="small"
            :disabled="formDisabled"
          >
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="邮箱地址" prop="email">
                  <el-input v-model="mailForm.email"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="btn-wrapper">
            <div v-show="!formDisabled">
              <el-button size="small" @click="formDisabled = true">
                返回
              </el-button>
              <el-button size="small" type="primary" @click="handleSubmit">
                保存
              </el-button>
            </div>
            <el-button
              v-show="formDisabled"
              size="small"
              type="primary"
              @click="formDisabled = false"
            >
              变更
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '../../bill-financing/api'
import { getCompanyDetail, getInvoiceDetail, saveInvoiceDetail } from './api'
import config from '@/config'

export default {
  data() {
    const invoiceForm = {
      id: '',
      companyName: '',
      taxpayerIdentificationNumber: '',
      registerAddress: '',
      phone: '',
      bank: '',
      bankNum: '',
    }
    const invoiceFormRules = {}

    Object.keys(invoiceForm).forEach(key => {
      invoiceFormRules[key] = [
        { required: true, message: '输入框不能为空', trigger: 'blur' },
      ]
    })
    return {
      hrefUrl: '', // 需要修改企业信息,所以需要这个字段
      userId: '',
      active: '1',
      invoiceForm,
      invoiceFormRules,
      mailForm: {
        email: '',
      },
      mailFormRules: {
        email: [{ required: true, message: '输入框不能为空', trigger: 'blur' }],
      },
      formDisabled: true,
      companyDetail: {},
    }
  },
  mounted() {
    this._getInvoiceDetail()
    this._getCompanyDetail()
    this._getUserInfo()
  },
  methods: {
    async _getUserInfo() {
      try {
        if (this.$store.state.username) {
          const { userId } = await getUserInfo()
          if (process.env.VUE_APP_NODE_ENV === 'prod') {
            this.hrefUrl = `http://10.122.83.51:6060/receipt-api/realname?userId=${userId}`
          } else {
            this.hrefUrl = `${config.baseUrl}/receipt-api/realname?userId=${userId}`
          }
          this.userId = userId
        }
      } catch (err) {
        console.error(err)
      }
    },
    async _getCompanyDetail() {
      try {
        const result = await getCompanyDetail()
        if (result) {
          this.companyDetail = result
          this.companyId = result.id
        }
      } catch (err) {
        console.error(err)
      }
    },
    async _getInvoiceDetail() {
      try {
        const result = await getInvoiceDetail()
        Object.keys(this.invoiceForm).forEach(key => {
          this.invoiceForm[key] = result[key]
        })
        this.mailForm.email = result.email
      } catch (err) {
        console.error(err)
      }
    },
    async handleSubmit() {
      try {
        const invoiceValid = await this.$refs.invoiceForm.validate()
        const mailValid = await this.$refs.mailForm.validate()

        if (invoiceValid && mailValid) {
          const submitData = {
            ...this.invoiceForm,
            ...this.mailForm,
            companyId: this.companyId,
          }
          await saveInvoiceDetail(submitData)
          this.$message.success('保存成功')
          this.formDisabled = true
        }
      } catch (err) {
        console.error(err)
      }
    },
    handleEdit() {
      window.location.href = this.hrefUrl
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  margin: 20px 0;
  background-color: #fff;
  box-sizing: border-box;
  padding: 30px;

  h2 {
    color: #333;
    line-height: 24px;
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.details {
  display: flex;
  margin-bottom: 20px;

  .left {
    width: 280px;
    padding: 30px 40px 0;
    box-sizing: border-box;
    background-color: #fff;
    margin-right: 20px;

    .editBtnBox {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-top: 20px;
      margin-bottom: 56px;
    }

    .img-wrapper {
      text-align: center;
      margin-bottom: 10px;
    }

    .auth-wrapper {
      margin-bottom: 18px;
      .inner {
        width: 112px;
        height: 28px;
        background: #2c68ff;
        border-radius: 18px;
        line-height: 28px;
        color: #fff;
        font-size: 14px;
        text-align: center;
        margin: 0 auto;
      }
    }

    .company-name {
      font-size: 16px;
      color: #333;
      line-height: 24px;
      margin-bottom: 8px;
    }

    .auth-last-time {
      font-size: 14px;
      color: #999;
      line-height: 24px;
      margin-bottom: 20px;
    }

    .list {
      li {
        margin-bottom: 52px;
        a {
          display: block;
          height: 28px;
          font-size: 16px;
          color: #999;
          line-height: 28px;
          padding-left: 48px;

          &:hover {
            color: #2c68ff;
          }
        }

        .active {
          color: #2c68ff;
        }

        .auth {
          background: url('../image/icon1.png') no-repeat;
        }

        .invoice {
          background: url('../image/icon2.png') no-repeat;
        }
      }
    }
  }

  .right {
    flex: 1;
    background-color: #fff;
    box-sizing: border-box;
    padding: 32px;

    .title {
      background: #f6f8fc;
      line-height: 40px;
      font-size: 14px;
      color: #333;
      padding-left: 16px;
      font-weight: 600;

      span {
        font-size: 14px;
        color: #949393;
      }
    }

    .list {
      padding: 14px 0 14px 16px;
      li {
        display: flex;
        font-size: 14px;
        color: #333;
        line-height: 36px;

        .content {
          flex: 1;
        }
      }
    }

    .invoiceForm {
      margin-top: 20px;
    }
    .btn-wrapper {
      margin-top: 20px;
      text-align: center;

      .el-button {
        width: 96px;
      }
    }
  }
}
</style>
